import {Injectable} from '@angular/core';
import {UserProfile} from '@app/interfaces/entities/user-profile';
import {UserApiService} from '@app/services/api/user-api.service';
import {distinctUntilChanged, Observable, ReplaySubject} from 'rxjs';
import {Router} from '@angular/router';
import {NotificationService} from '@app/services/notification.service';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  private userProfile$: ReplaySubject<UserProfile>;

  constructor(private userApiService: UserApiService, private router: Router, private notificationService: NotificationService) {
  }

  public loadUserProfile(): Observable<UserProfile> {
    if (!this.userProfile$) {
      this.userProfile$ = new ReplaySubject<UserProfile>(1);
    }
    this.userApiService.getUserProfile().subscribe((userProfile) => {
      if (userProfile) {
        userProfile.data.originalLocale = userProfile.data.locale;
        userProfile.data.locale = userLocales[userProfile.data.locale];

        this.userProfile$.next(userProfile.data);

        if (!userProfile.data.profile.firstName || !userProfile.data.profile.lastName) {
          this.router.navigate(['/admin/settings/profile']);
          this.notificationService.showErrorAlert($localize`:@@notification.info-message-missing-profile-data:Dopolnite manjkajoče podatke na osebnem profilu`);
        }
      }
    });

    return this.getUserProfileUniqueEvents();
  }

  public getUserProfile(): Observable<UserProfile> {
    if (!this.userProfile$) {
      return this.loadUserProfile();
    }
    return this.getUserProfileUniqueEvents();
  }

  private getUserProfileUniqueEvents(): Observable<UserProfile> {
    return this.userProfile$.pipe(distinctUntilChanged((prev, curr) => JSON.stringify(prev) === JSON.stringify(curr)));
  }
}

export enum userLocales {
  sl = 'sl-SI',
  hr = 'hr-HR',
  en = 'en-US',
  de = 'de-DE'
}
