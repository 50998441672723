<div [style.display]="isHidden ? 'none' : 'block'">
  <label class="text-grayDark text-xs" *ngIf="!hideLabel">
    <ng-container *ngIf="field.type !== 'vehicle_model' && field.config.label">
      <span class="leading-normal">{{ field.config.label }}</span>
    </ng-container>
    <app-tooltip
            *ngIf="field.config.description"
            class="ml-1" [content]="field.config.description"></app-tooltip>
  </label>

  <div class="flex flex-row" [style.min-width]="minWidth !== 'undefined' ? minWidth : 'auto'"
       *ngIf="fieldEditMap.has(field.fieldUuid); else showFieldValue">
    <div class="grow">
      <app-field-input [field]="fieldEditMap.get(field.fieldUuid).updatedField"
                       [hideLabel]="true"
                             [disabled]="isSaving"
                             [formGroup]="formGroup || fieldEditMap.get(field.fieldUuid).formGroup"></app-field-input>
        </div>
        <div class="block flex flex-row content-center justify-center">
            <button
                    data-test="save-edit-button"
                    class="btn p-2 border-transparent text-base text-gray shadow-none mt-1 text-primary hover:text-greenHover fadeInFadeOut"
                    style="border: transparent !important;"
                    [disabled]="isSaving || fieldEditMap.get(field.fieldUuid).formGroup.controls[field.fieldUuid].invalid"
              type="button" (click)="saveEdit(field)"><i
              class="fas fa-check-circle"></i></button>
      <button
              class="btn p-2 border-transparent text-base text-gray shadow-none mt-1 text-red hover:text-redHover fadeInFadeOut"
              style="border: transparent !important;"
              [disabled]="isSaving"
              type="button" (click)="cancelEdit(field)"><i
              class="fas fa-times-circle"></i></button>
    </div>
  </div>

  <ng-template #showFieldValue>
    <div class="text-grayDark" [ngClass]="getClass()"
         [attr.data-field]="field?.fieldUuid"
         [attr.data-typeId]="field?.config?.typeId"
    >
      <div class="flex flex-row">
        <div class="flex items-center"
             [ngClass]="{'font-bold': !removeBoldFromValue, 'grow': expandToFullSize, 'text-black': removeBoldFromValue}">
          <app-field-table-value [field]="field"></app-field-table-value>
          <span *ngIf="field.value === null">{{ replaceNullValueWith }}</span>
        </div>
        <div class="relative pl-2" *ngIf="isEditable">
          <button class="btn border-transparent text-gray shadow-none btn-sm hover:text-grayDark"
                  style=""
                  data-test="open-field-value-button"
                  type="button" (click)="editOrOpenEditDialog(field)"
                  [attr.tabIndex]="0"><i class="fas fa-pen"></i>
          </button>
        </div>
      </div>
    </div>

    <ng-container *ngIf="formGroup">
      <app-field-errors [control]="formGroup.controls[this.field.fieldUuid]" [hasToBeTouched]="this.hasToBeTouched"></app-field-errors>
    </ng-container>
  </ng-template>
</div>
